/***************************************************************************************************@fortawesome/fontawesome-free/js
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import 'core-js/client/shim';
import 'reflect-metadata';
import 'zone.js/dist/zone';
import 'ts-helpers';
// IE9, IE10 and IE11 requires all of the following polyfills.
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

//add Notification (Safari complains)
import './polyfills/notifications';
